import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { UnitData } from '../Home';
import { ContentPlanData } from '../ContentPlanHome';
import { ContentPlansContext } from '../LeftRail';

function GenerateBar({ contentPlan = null, setIsLoading }: {
    contentPlan?: ContentPlanData | null,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {

    const [formData, setFormData] = useState({
        search: contentPlan?.prompt ?? ''
    });
    const [selectedOption, setSelectedOption] = useState(contentPlan?.pov ?? 'value');
    const plan = useContext(ContentPlansContext);
    let navigate = useNavigate();

    const onClick = (event) => {
        if (event != null) {
            event.preventDefault();
        }
        let { search } = formData;
        console.log(search);
        if (search === '') {
            return;
        }
        if (search === contentPlan?.prompt && selectedOption === contentPlan?.pov) {
            return;
        }
        setIsLoading(true);
        axios.post('/generate', { query: search, pov: selectedOption })
            .then((response) => {
                /*
                content_ideas: contentIdeas,
                prompt: query,
                plan_id: planDocId
                */
                const data = response.data;
                setIsLoading(false);
                if (search !== contentPlan?.prompt) {
                    const cp = [{ id: data.plan_id, title: data.prompt }, ...plan.contentPlans];
                    plan.setContentPlans(cp);
                }
                navigate(`/${data.plan_id}`);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [rows, setRows] = useState(1);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    return (
        <Container className="mt-5">
            <Row style={{ justifyContent: 'center' }}>
                <Col sm={7}>
                    <div style={{ paddingBottom: '12px' }}>
                        <h5>AI Content Generate</h5>
                    </div>
                    <Form>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Control
                                style={{ height: 'inherit', width: '500px' }}
                                ref={textAreaRef}
                                defaultValue={contentPlan?.prompt}
                                size="lg"
                                type="search"
                                placeholder="🤖 Write a topic to generate a content plan"
                                as="textarea"
                                rows={rows}
                                className="me-2"
                                aria-label="Generate"
                                name='search'
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                    const scrollHeight = textAreaRef.current?.scrollHeight;
                                    let rows = 1;
                                    if (scrollHeight != null) {
                                        rows = Math.floor(Math.min(scrollHeight, 106) / 30);
                                    }
                                    setRows(rows);
                                }}
                            />
                            <Button size="lg" variant="primary" style={{ marginLeft: '8px', height: '48px' }} onClick={(event) => { onClick(event) }}>
                                Generate
                            </Button>
                        </div>
                        <div style={{ paddingTop: '28px', maxWidth: '500px' }}>
                            <p style={{ color: '#737373' }}>Send prompt to generate a personalized content plan for you. Generation may take some time, we are working on improving speed.</p>
                        </div>
                        <div style={{ maxWidth: '500px' }}>
                            <Card>
                                <Card.Body style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <Form.Check
                                        label="Audience Value"
                                        name="group1"
                                        value="value"
                                        checked={selectedOption === "value"}
                                        onChange={handleOptionChange}
                                        type={"radio"}
                                        id={`reverse-radio-1`}
                                    />
                                    <Form.Check
                                        label="Entertaining"
                                        name="group1"
                                        type={"radio"}
                                        value="entertainment"
                                        checked={selectedOption === "entertainment"}
                                        onChange={handleOptionChange}
                                        id={`reverse-radio-2`}
                                    />
                                    <Form.Check
                                        label="Informative"
                                        type={"radio"}
                                        name="group1"
                                        value="informative"
                                        checked={selectedOption === "informative"}
                                        onChange={handleOptionChange}
                                        id={`reverse-radio-3`}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>);
}

export default GenerateBar;
