import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, ListGroup, OverlayTrigger, Popover, Row, Stack } from 'react-bootstrap';
import icon from '../assets/musicicon.png';

// ♬

type SongsUnit = {
    songs: {
        name: string
        href: string
    }[]
}

function SongsUnit(data: SongsUnit) {

    return (
        <Card style={{height: '100%'}}>
            <Card.Body>
                <Card.Title>Top Songs</Card.Title>
                <Card.Text>
                <ListGroup variant="flush">
                    {data.songs.map((obj) => {
                        return (<ListGroup.Item><img
                            style={{marginRight: '8px'}}
                            src={icon}
                            width={24}
                            height={24}
                            alt="refresh-icon"
                        /><a href={obj.href}>{obj.name}</a></ListGroup.Item>);
                    })}
                </ListGroup>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default SongsUnit;
