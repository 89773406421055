import { useEffect, useState } from 'react';
import {ListGroup } from 'react-bootstrap';
import icon from './assets/Order.png';
import vectordown from './assets/Expand_down.png';
import vectorup from './assets/Expand_up.png';
import {createClient} from 'contentful';
import metadataJson from './metadata.json';
import { useNavigate, useParams } from 'react-router-dom';
import { ShimmerCategoryItem } from "react-shimmer-effects";

type TipsData = {
    id: string,
    title: string,
};

function TipsAndTricksSection() {
    const [tips, setTips] = useState<TipsData[]>([]);
    const params = useParams();
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async ()=> {
            const client = createClient({accessToken: metadataJson.accessToken, space: metadataJson.space});
            const entries = await client.getEntries();
            const titles = entries.items.map((item) => ({title: item.fields.title as string, id: item.sys.id}));
            setTips(titles);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    const [isExpanded, setIsExpanded] = useState(true);
    let items = (<>{tips.map((item) => {
            return (<ListGroup.Item action onClick={() => {
                navigate(`tips/${item.id}`);
            }}>{params.id === item.id ? (<b>{item.title}</b>) : item.title}</ListGroup.Item>);
        })}
    </>);
    if (isLoading) {
        items = (
            <>
                <ListGroup.Item>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
                <ListGroup.Item>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
                <ListGroup.Item>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
            </>
        );
    }
    return (
        <ListGroup>
            <ListGroup.Item action onClick={() => { setIsExpanded(!isExpanded) }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '-.5rem' }}>
                        <img
                            style={{ marginRight: '16px', marginBottom: '.5rem' }}
                            src={icon}
                            width={20}
                            height={20}
                            alt="order-icon"
                        /><h5>Tips & Tricks</h5>
                    </div>
                    {isExpanded ?
                        <img
                            style={{ marginLeft: '16px' }}
                            src={vectordown}
                            width={24}
                            height={24}
                            alt="vector-down"
                        /> : <img
                            style={{ marginLeft: '16px' }}
                            src={vectorup}
                            width={24}
                            height={24}
                            alt="vector-up"
                        />}
                </div>
            </ListGroup.Item>
            {isExpanded ? items : null}
        </ListGroup>
    );
}

export default TipsAndTricksSection;
