import { Row, Col, Container, Stack, Button, Image } from 'react-bootstrap';
import ContentIdeas from './ContentIdeas';
import TagAggUnit from './TagAggUnit';
import TitleUnit from './TitleUnit';
import AuthorUnit from './AuthorUnit';
import SongsUnit from './SongsUnit';
import VideoSection from './VideoSection';
import CompetitionSection from './CompetitionSection';
import { ContentIdeaData, ContentPlanData } from '../ContentPlanHome';
import { useState } from 'react';
import ContentResearchSection from './ContentResearchSection';


function ContentPlan({ contentPlan, setContentPlan, isContentIdeasLoading, setIsContentIdeasLoading }: {
    contentPlan: ContentPlanData,
    setContentPlan: React.Dispatch<React.SetStateAction<ContentPlanData | null>>
    isContentIdeasLoading: boolean,
    setIsContentIdeasLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) {
    const [selectedIdea, setSelectedIdea] = useState<ContentIdeaData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    return (
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', marginTop: '56px' }}>
            <Col sm={9}>
                <Stack>
                    <div>
                        <ContentIdeas
                            isContentIdeasLoading={isContentIdeasLoading}
                            setIsContentIdeasLoading={setIsContentIdeasLoading}
                            contentPlan={contentPlan}
                            selectedIdea={selectedIdea}
                            setSelectedIdea={setSelectedIdea}
                            setIsLoading={setIsLoading}
                            setContentPlan={setContentPlan}
                        />
                    </div>
                    {isContentIdeasLoading !== true && selectedIdea != null &&
                        <ContentResearchSection contentPlan={contentPlan} selectedIdea={selectedIdea} isLoading={isLoading} setIsLoading={setIsLoading} />}
                </Stack>
            </Col>
        </div>
    );
}

export default ContentPlan;
