import React from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import logo from './assets/logo_footer.png';

const Footer = () => {
  return (
    <Container fluid style={{marginTop: '100px'}}>
        <hr
            style={{
            borderTop: '1px solid #DDDDDD',
            margin: '1rem 0',
            }}
        />
        <Row>
            <Col sm={6} className="text-center text-sm-left">
                <Stack>
                    <img
                        src={logo}
                        width={100}
                        height={100}
                        alt="CreatorPush footer logo"
                    />
                    <p style={{marginLeft: '15px'}} className="lead"><small>Copyright © 2023 CreatorPush - All Rights Reserved.</small></p>
                </Stack>
            </Col>
        </Row>
    </Container>
  );
};

export default Footer;