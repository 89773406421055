import { useState } from 'react';
import {ListGroup } from 'react-bootstrap';
import icon from './assets/Group.png';
import discordlogo from './assets/discordlogo.png';
import vectordown from './assets/Expand_down.png';
import vectorup from './assets/Expand_up.png';

function CommunitySection() {
    const [isExpanded, setIsExpanded] = useState(false);
    const items = (<>
        <ListGroup.Item action href="https://discord.gg/DsV9sBWb" target="_blank" active={false}><img
                            src={discordlogo}
                            width={48}
                            height={48}
                            alt="discord-logo"
                        />Join Discord Server</ListGroup.Item>
    </>);
    return (
        <ListGroup>
            <ListGroup.Item action onClick={() => { setIsExpanded(!isExpanded) }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '-.5rem' }}>
                        <img
                            style={{ marginRight: '16px', marginBottom: '.5rem' }}
                            src={icon}
                            width={20}
                            height={20}
                            alt="order-icon"
                        /><h5>Community</h5>
                    </div>
                    {isExpanded ?
                        <img
                            style={{ marginLeft: '16px' }}
                            src={vectordown}
                            width={24}
                            height={24}
                            alt="vector-down"
                        /> : <img
                            style={{ marginLeft: '16px' }}
                            src={vectorup}
                            width={24}
                            height={24}
                            alt="vector-up"
                        />}
                </div>
            </ListGroup.Item>
            {isExpanded ? items : null}
        </ListGroup>
    );
}

export default CommunitySection;
