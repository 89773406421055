import { useState } from 'react';
import { Card, Col, Container, ListGroup, Row, Image, Badge } from 'react-bootstrap';
import { ShimmerCircularImage } from "react-shimmer-effects";

type AuthorUnit = {
    authors: {
        username: string,
        stats: {
            followers: string,
            likes: string
        },
        profile_uri: string
    }[]
}

function AuthorUnit(data: AuthorUnit) {
    const [shimmer, setShimmer] = useState(false); 

    if (shimmer === true) {
        return <ShimmerCircularImage imageHeight={35} card title text cta />;
    }

    return (
        <Card style={{height: '100%'}}>
            <Card.Body>
                <Card.Title>Top Creators</Card.Title>
                <Card.Text>
                    <ListGroup variant="flush">
                        {data.authors.map((obj) => {
                            return (
                                <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image width={35} src={`https://storage.googleapis.com/creator_push_images/images/${obj.username}_prof.jpg`} roundedCircle={true} onError={({currentTarget}) => {
                                    if (currentTarget.src === obj.profile_uri) {
                                        setShimmer(true);
                                    }
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=obj.profile_uri;
                                }}/>
                                    <b style={{ marginLeft: '8px' }}><a href={`https://tiktok.com/${obj.username}`}>{obj.username}</a></b>
                                    <span style={{ marginLeft: '8px' }}>
                                        <Badge bg="light" text="dark">{`${obj.stats.followers} followers`}</Badge>{' '}
                                        <Badge bg="light" text="dark">{`${obj.stats.likes} likes`}</Badge>
                                    </span>
                                </ListGroup.Item>);
                        })}
                    </ListGroup>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default AuthorUnit;
