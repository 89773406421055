import Stack from 'react-bootstrap/Stack';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import logo from './assets/icon.png';
import WorkWithAIGroupSection from './WorkWithAIGroupSection';
import TipsAndTricksSection from './TipsAndTricksSection';
import CommunitySection from './CommunitySection';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';

function IntroVideo({ children }) {
    const [showPopover, setShowPopover] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams);
    if (searchParams.has('showIntroVideo')) {
      return (
        <>
          {children}
          <Modal show={showPopover} onHide={() => setShowPopover(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Intro Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{width: '100%', display:'flex', justifyContent: 'center'}}>
                    <iframe width="500" height="300" src="https://www.youtube.com/embed/plgVHy4af4U" title="How to use CreatorPush" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>
                </Modal.Body>
            </Modal>
        </>
      )
    }
    return children;
}

export default IntroVideo;
