import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import Dashboard from './home_dashboard/Dashboard';
import GenerateBar from './home_dashboard/GenerateBar';
import { useParams } from 'react-router-dom';
import ContentPlan from './home_dashboard/ContentPlan';
import { ShimmerPostDetails } from "react-shimmer-effects";
import {createClient} from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import metadataJson from './metadata.json';
import { Document, Link } from "@contentful/rich-text-types";
import ReactPlayer from 'react-player'

type ContentData = {
  id: string,
  title: string,
  descriptionHtml: string,
  videoUri: string | null,
  ytEmbedSrc: {
    src: string
  } | null,
}

function TipPage() {
  const params = useParams();
  const id = params.id;
  const [content, setContent] = useState<ContentData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      setIsLoading(true);
      const fetchData = async ()=> {
          if (id) {
            const client = createClient({accessToken: metadataJson.accessToken, space: metadataJson.space});
            const entry = await client.getEntry(id);
            const ytEmbedSrc = entry.fields.hasOwnProperty('ytEmbedSrc') ? entry.fields.ytEmbedSrc : null;
            const desc = entry.fields.description as Document;
            const assetLink = entry.fields.hasOwnProperty('media') ? entry.fields.media as Link : null;
            const assetID = assetLink?.sys.id ?? null;
            let asset: any = null;
            if (assetID != null) {
              asset = await client.getAsset(assetID);
            }
            setContent({
              id,
              title: entry.fields.title as string,
              descriptionHtml: documentToHtmlString(desc),
              videoUri: asset?.fields?.file?.url ?? null,
              ytEmbedSrc: ytEmbedSrc as any,
            });
            setIsLoading(false);
          }
      }
      fetchData();
  }, [params.id]);

  if (content == null) {
    return (<div style={{ width: '100%' }}>
      <Stack>
        <div style={{paddingLeft: 64, paddingRight: 64}}>
          <ShimmerPostDetails card cta variant="SIMPLE" />
        </div>
      </Stack>
    </div>);
  }
  console.log(content.videoUri?.slice(2));
  return (
    <div style={{ width: '100%' }}>
      <Stack>
        <Container className="mt-5">
              <Row style={{ justifyContent: 'center' }}>
                  <Col sm={9}>
                    <h1>{content.title}</h1>
                    {content.videoUri != null && 
                      <ReactPlayer 
                        url={`https:${content.videoUri}`}
                        controls={true}
                      />}
                    {content.ytEmbedSrc != null &&
                      <iframe width="600" height="350" src={content.ytEmbedSrc.src} title="video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    }
                    <div style={{marginTop: '32px'}}>
                      <span>
                        <div dangerouslySetInnerHTML={{ __html: content.descriptionHtml }} />
                      </span>
                    </div>
                  </Col>
              </Row>
        </Container>
      </Stack>
    </div>
  );
}

export default TipPage;
