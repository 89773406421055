import React, { useState } from 'react';
import { Alert, Card, Form, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function Register() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
  });
  const navigate = useNavigate();

  const[validationMsg, setValidationMsg] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    const {username, email, password, passwordConfirm} = formData;
    if (username == '' || email == '' || password == '' || passwordConfirm == '') {
      setValidationMsg('Some fields are empty.');
      return;
    }
    if (password !== passwordConfirm) {
      setValidationMsg('Passwords do not match.');
      return;
    }
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      setValidationMsg('Invalid email format.');
      return;
    }
    const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    const isValidPassword = passwordRegex.test(password);
    if (!isValidPassword) {
      setValidationMsg('Invalid password format.');
      return;
    }
    const instagramUsernameRegex: RegExp = /^[a-zA-Z0-9._]{1,30}$/;
    const isValidInstagramUsername = instagramUsernameRegex.test(username);
    if (!isValidInstagramUsername) {
      setValidationMsg('Invalid Insta/Tiktok username format.');
      return;
    }

    axios.post('/register', formData)
      .then((response) => {
        navigate('/login', { state: { isRegister: true } });
      })
      .catch((error) => {
        setValidationMsg(error.response.data.message);
     });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack style={{ width: '18rem', marginTop: '100px' }}>
        {validationMsg != '' && <Alert key={'danger'} variant={'danger'}>
          {validationMsg}
          {validationMsg === 'Invalid password format.' ? 
            <ul style={{marginTop: '10px'}}>
              <li>The password must contain at least 8 characters.</li>
              <li>The password must contain at least one digit.</li>
              <li>The password must contain at least one lowercase letter.</li>
              <li>The password must contain at least one uppercase letter.</li>
              <li>The password must contain at least one special character (!@#$%^&*).</li>
            </ul> : null}
        </Alert>}
        <Card>
          <Card.Body>
            <Card.Title>Register</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Insta or Tiktok Username</Form.Label>
                <Form.Control type="name" placeholder="Insta or Tiktok Username" name='username' onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name='email' onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name='password' onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formConfirmBasicPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Confirm Password" name='passwordConfirm' onChange={handleChange} />
              </Form.Group>
              <div style={{ marginTop: '30px' }}>
                <Button variant="primary" type="submit">
                  Register
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Stack>
    </div>
  );
}

export default Register;
