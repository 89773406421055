import React, { ComponentType, useContext, useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import roboticon from './assets/roboticon.png';
import vectordown from './assets/Expand_down.png';
import vectorup from './assets/Expand_up.png';
import axios from 'axios';
import LinesEllipsis from 'react-lines-ellipsis';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentPlansContext } from './LeftRail';
import { ShimmerCategoryItem } from "react-shimmer-effects";

function WorkWithAIGroupSection() {
    const plan = useContext(ContentPlansContext);
    const params = useParams();
    const currentSelectedPlanID = params.planID;
    const [isExpanded, setIsExpanded] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        axios.get('/plan')
            .then((response) => {
                const data = response.data;
                plan.setContentPlans(data.content_plans);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    const itemCol = plan.contentPlans.map((plan, idx) => {
        return (<ListGroup.Item action key={idx} onClick={() => {
            navigate(`/${plan.id}`);
        }}>
            <LinesEllipsis
                text={currentSelectedPlanID === plan.id ? <b>{plan.title}</b> : plan.title}
                maxLine='3'
                ellipsis='...'
                trimRight
                basedOn='letters'
            />
        </ListGroup.Item>);
    })

    let items = (<>{itemCol}</>);
    if (isLoading) {
        items = (
            <>
                <ListGroup.Item>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
                <ListGroup.Item>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
                <ListGroup.Item>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
            </>
        );
    }
    return (
        <ListGroup>
            <ListGroup.Item action onClick={() => { setIsExpanded(!isExpanded) }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '-.5rem' }}>
                        <img
                            style={{ marginRight: '16px', marginBottom: '.5rem' }}
                            src={roboticon}
                            width={20}
                            height={20}
                            alt="robot-icon"
                        /><h5>Work with AI</h5>
                    </div>
                    {isExpanded ?
                        <img
                            style={{ marginLeft: '16px' }}
                            src={vectordown}
                            width={24}
                            height={24}
                            alt="vector-down"
                        /> : <img
                            style={{ marginLeft: '16px' }}
                            src={vectorup}
                            width={24}
                            height={24}
                            alt="vector-up"
                        />}
                </div>
            </ListGroup.Item>
            {isExpanded ? items : null}
        </ListGroup>
    );
}

export default WorkWithAIGroupSection;
