import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import LeftRail from './LeftRail';
import NavbarContainer from './NavbarContainer';
import Footer from './Footer';
import axios from 'axios';
import ContentPlan from './ContentPlanHome';
import TipPage from './TipPage';
import Onboarding from './Onboarding';
import IntroVideo from './IntroVideo';
import { Col, Row, Stack } from 'react-bootstrap';
import icon from './assets/laptopicon.png';

function App() {
  useEffect(() => {
    axios.post('/logging/app-impression');
  }, []);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.screen.width < 576);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let component;
  if (isSmallScreen) {
    component = (
      <Stack style={{display: 'flex', flexDirection: 'column', margin: '32px'}}>
        <img src={icon} style={{margin: '32px'}} />
        <Row>
          <Col sm={7}>
              <div>
                  <b>
                      CreatorPush is on Desktop for Now
                  </b>
              </div>
              <div style={{ paddingTop: '8px' }}>
                  <p>Thank you for logging on mobile! For now, CreatorPush is available on computer/laptop. 
                    Go to app.thecreatorpush.com on your computer to get access to our full suite of features!</p>
              </div>
          </Col>
        </Row>
      </Stack>
    );
  } else {
    component = <IntroVideo><LeftRail><Outlet /></LeftRail></IntroVideo>;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
      <NavbarContainer />
      <div style={{flexGrow: '1'}}>
        <Router>
          <Routes>
            <Route path="/" element={<PrivateRoute>{component}</PrivateRoute>}>
              <Route index={true} element={<Home/>} />
              <Route path=":planID" element={<ContentPlan />} />
              <Route path="tips/:id" element={<TipPage />} />
            </Route>
            <Route path="/onboarding" element={<PrivateRoute><Onboarding /></PrivateRoute>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
