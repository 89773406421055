import axios from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, OverlayTrigger, Popover, Row, Stack } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis'

function VidPopover({ embed }: { embed: string }) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
            /*const list = document.head.getElementsByTagName('link');
            const num = list.length;

            for (let i = 0; i < num; i = i++) {
                if (list[i].getAttribute('rel') === 'preconnect') {
                    document.head.removeChild(list[i]);
                }
            }*/
        }
    }, []);

    return (<div dangerouslySetInnerHTML={{ __html: embed }} />);
}

export default VidPopover;
