import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Card, Container, Form, Stack, Row, Col, ButtonGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { ShimmerCategoryItem } from "react-shimmer-effects";

function Onboarding() {

  const [formData, setFormData] = useState({
    ima: '',
    ipostabout: ''
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // Perform any necessary form submission logic here
    if (formData.ima.trim() === '') {
      window.location.href = '/'
      return;
    }
    if (formData.ipostabout.trim() === '') {
      window.location.href = '/'
      return;
    }

    axios.post('/onboarding', { ima: formData.ima, ipostabout: formData.ipostabout })
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        const planID = data.plan_id;
        window.location.href = `/${planID}?showIntroVideo=1`;
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        window.location.href = '/?showIntroVideo=1';
      });
  };

  if (isLoading) {
    return (
      <div style={{ width: '100%' }}>
        <Stack style={{ width: '100%', marginTop: '100px' }}>
          <Container>
            <Row>
              <Col>
                <ShimmerCategoryItem />
                <ShimmerCategoryItem/>
                <ShimmerCategoryItem />
                <ShimmerCategoryItem/>
              </Col>
            </Row>
          </Container>
        </Stack>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Stack style={{ width: '100%', marginTop: '100px' }}>
        <Container>
          <Row>
            <Col sm={7}>
              <div>
                <p style={{ color: '#737373' }}>Configure your AI</p>
              </div>
              <div>
                <h2>
                  How would you describe yourself as a <b>Content Creator?</b>
                </h2>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '32px' }}>
            <Col sm={7}>
              <Form onSubmit={handleSubmit}>
                <Form.Group style={{ width: '100%' }}>
                  <Form.Label>I am a...</Form.Label>
                  <Form.Control
                    value={formData.ima}
                    onChange={handleChange}
                    size="lg"
                    type="ima"
                    placeholder=""
                    as="textarea"
                    aria-label="I am a"
                    name='ima'
                  />
                </Form.Group>
                <Form.Group style={{ width: '100%', marginTop: '32px' }}>
                  <Form.Label>I usually post about...</Form.Label>
                  <Form.Control
                    value={formData.ipostabout}
                    onChange={handleChange}
                    size="lg"
                    type="ipostabout"
                    placeholder=""
                    as="textarea"
                    aria-label="I usually post"
                    name='ipostabout'
                  />
                </Form.Group>
                <div>
                  <Button type="submit" size="lg" variant="primary" style={{ marginTop: '32px', height: '48px' }}>
                    Done
                  </Button>
                  <Button size="lg" variant="outline-primary" style={{ marginTop: '32px', height: '48px', marginLeft: '12px' }} onClick={(event) => { window.location.href = '/' }}>
                    Skip
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Stack>
    </div>
  );
}

export default Onboarding;
