import { Row, Col, Container, Stack, Button, Image } from 'react-bootstrap';
import ContentIdeas from './ContentIdeas';
import TagAggUnit from './TagAggUnit';
import TitleUnit from './TitleUnit';
import AuthorUnit from './AuthorUnit';
import SongsUnit from './SongsUnit';
import VideoSection from './VideoSection';
import CompetitionSection from './CompetitionSection';
import { ContentIdeaData, ContentPlanData } from '../ContentPlanHome';
import React, { ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import icon from '../assets/robot.png';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader';
import erroredasset from '../assets/errored.png';
import notfoundasset from '../assets/404research.png';

export type PlanData = {
  tags: TagMetadata[],
  songs: SongsMetadata[],
  video_metadata: VideoMetadata[],
  author_metadata: AuthorMetadata[],
}

type SongsMetadata = {
  text: string,
  uri: string
};

export type VideoMetadata = {
  id: string,
  video_uri: string,
  author: string,
  video_stats: { like: string, comment: string, share: string },
  embed?: {
    html: string,
    thumbnail_width: number,
    thumbnail_height: number,
    thumbnail_url: string,
    title: string
  },
};

type AuthorMetadata = {
  author: string,
  author_stats: {
    avg_views: number,
    median_views: number,
    image_uri: string,
    author_stats: {
      agg_likes: string,
      followers: string
    }
  }
};

type TagMetadata = {
  tag: string
};

const POLL_INTERVAL = 30000; // 30 seconds in milliseconds

function ContentResearchSection({ selectedIdea, contentPlan, isLoading, setIsLoading }: {
  selectedIdea: ContentIdeaData,
  contentPlan: ContentPlanData,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {

  const [planData, setPlanData] = useState<PlanData | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isErrored, setIsErrored] = useState(false);
  useEffect(() => {
    stopPolling();
    if (isLoading === false) {
      return;
    }

    const getData = () => {
      console.log("polling");
      axios.get(`/generate/get-result/${contentPlan.id}/${selectedIdea.id}`)
        .then((response) => {
          const data = response.data;
          const isLoading = data.is_loading;
          setIsErrored(data.is_errored ?? false);
          setIsLoading(data.is_loading);
          if (isLoading === false) {
            stopPolling();
          }
          setPlanData(data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    function startPolling() {
      setIsLoading(true);
      setPlanData(null);

      getData();

      const id = setInterval(getData, POLL_INTERVAL);
      setIntervalId(id);
    }

    function stopPolling() {
      if (intervalId == null) {
        return;
      }
      clearInterval(intervalId);
    }

    startPolling(); // start the polling

    return () => {
      stopPolling(); // stop the polling when the component unmounts
    };
  }, [isLoading, selectedIdea.id]);

  if (isLoading !== true && isErrored === true) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}><img
      src={erroredasset}
      width={391}
      height={445}
      alt="error"
    /></div>;
  }

  let loadingIndicator: ReactNode | null = null;

  if (isLoading === true) {
    loadingIndicator = <div><BouncingCircle /></div>;
  }

  const isEmptyPlan = (planData: PlanData) => {
    return planData.tags.length === 0 &&
      planData.author_metadata.length === 0 &&
      planData.video_metadata.length === 0 &&
      planData.songs.length === 0;
  }

  let content: ReactNode | null = null;
  if (planData != null) {
    if (isLoading !== true && isEmptyPlan(planData)) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}><img
          src={notfoundasset}
          width={391}
          height={445}
          alt="error"
        /></div>
      );
    } else if (isLoading === true && isEmptyPlan(planData)) {
      content = null;
    } else {
      content = (
        <div>
          <div style={{ marginTop: '56px' }}>
            <Container>
              <Row>
                <Col><TagAggUnit tags={planData.tags.map(item => {
                  const pattern: RegExp = /\/tag\/([^\/?#]+)/;

                  const match: RegExpMatchArray | null = item.tag.match(pattern);
                  if (match == null) {
                    return null;
                  }
                  return {
                    tag: match[1]
                  };
                }).filter(Boolean) as TagMetadata[]} /></Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col><AuthorUnit authors={planData.author_metadata.map(author => ({
                  username: author.author,
                  profile_uri: author.author_stats.image_uri,
                  stats: {
                    followers: author.author_stats.author_stats.followers,
                    likes: author.author_stats.author_stats.agg_likes
                  }
                }))} /></Col>
                <Col><SongsUnit songs={planData.songs.map(song => ({
                  name: song.text,
                  href: song.uri
                }))} /></Col>
              </Row>
            </Container>
          </div>
          <div>
            <VideoSection planData={planData} />
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {loadingIndicator}
      {content}
    </>
  );
}

const BouncingCircle = () => {
  const springProps = useSpring({
    from: { y: 100 },
    to: { y: 0 },
    config: { mass: 1, tension: 200, friction: 60, clamp: true },
    reset: true,
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
      <animated.div
        style={{
          width: '400px',
          height: '100px',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          transform: springProps.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
        }}
      >
        <div style={{ position: 'absolute' }}>
          <RiseLoader color='#ffa400' />
        </div>
        <Image width={100} src={icon} roundedCircle={true} />
        <div>
          <span>
            AI Doing Work...
          </span>
        </div>
      </animated.div>
    </div>
  );
};


export default ContentResearchSection;
