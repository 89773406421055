import { Container, Row, Col } from "react-bootstrap";
import { ShimmerPostList, ShimmerPostItem } from "react-shimmer-effects";
import { PlanData, VideoMetadata } from "./ContentResearchSection";
import VidUnit from "./VidUnit";

function chunkArray(array: any[], chunkSize: number): any[][] {
    return array.reduce((chunks: any[][], element: any, index: number) => {
      if (index % chunkSize === 0) {
        chunks.push([element]);
      } else {
        chunks[chunks.length - 1].push(element);
      }
      return chunks;
    }, []);
  }

function VideoSection({planData}:{planData: PlanData}) {
    const chunkedVideosArr: VideoMetadata[][] = chunkArray(planData.video_metadata, 3);
    const videos = chunkedVideosArr.map((arr, i) => {
        const row: any[] = [];
        for (let j in arr) {
            const vid = chunkedVideosArr[i][j];
            if (vid.embed == null) {
                continue;
            }
            const vidElements = (
                <Col sm={4}>
                    <VidUnit 
                        id={vid.id}
                        embed={vid.embed.html} 
                        thumbnail_url={vid.embed.thumbnail_url} 
                        video_uri={vid.video_uri} 
                        title={vid.embed.title}
                        likes={vid.video_stats.like}
                        author={vid.author}
                    />
                </Col>
            );
            row.push(vidElements);
        }
        return (
            <Row style={{ paddingTop: '32px' }}>
                {row}
            </Row>
        );
    });
    return (
        <div style={{ width: '100%', marginTop: '56px' }}>
            <Container>
                <div>
                    <span style={{ fontWeight: 500 }}>
                        Examples to Get Inspired
                    </span>
                </div>
                <div style={{ paddingTop: '8px' }}>
                    <p style={{ color: '#737373' }}>These are top videos of what AI found while searching the internet. 
                    We believe in making great content by learning and analyzing other great content and building on top of it!</p>
                </div>
                {videos}
            </Container>
        </div>
    );
}

export default VideoSection;
