import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './bootstrap.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.baseURL = 'https://creator-push-service-tu6fymkbjq-uc.a.run.app';

// Add a request interceptor to attach the JWT token to each request
axios.interceptors.request.use(config => {
  const token = Cookies.get('jwtToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor to handle 401 and 403 errors and delete the JWT token
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      Cookies.remove('jwtToken');
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
