import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, ListGroup, OverlayTrigger, Popover, Row, Stack } from 'react-bootstrap';
import icon from '../assets/hashtagicon.png';

type TagAggUnit = {
    tags: {
        tag: string
    }[]
}

function TagAggUnit(data: TagAggUnit) {

    return (
        <Card style={{height: '100%'}}>
            <Card.Body>
                <Card.Title>Top Hashtags</Card.Title>
                <Card.Text>
                    <ListGroup variant="flush">
                        {data.tags.map((obj) => {
                            return (<ListGroup.Item><img
                                style={{marginRight: '8px'}}
                                src={icon}
                                width={24}
                                height={24}
                                alt="refresh-icon"
                            /><a href={`https://tiktok.com/tag/${obj.tag}`}>{`#${obj.tag}`}</a></ListGroup.Item>);
                        })}
                    </ListGroup>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default TagAggUnit;
