import { Row, Col, Container, Button, Card, ListGroup } from 'react-bootstrap';
import icon from '../assets/refreshorange.png';
import { ContentIdeaData, ContentPlanData } from '../ContentPlanHome';
import axios from 'axios';
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { useState } from 'react';
import graycheckicon from '../assets/graycheckicon.png';
import grayicon from '../assets/grayicon.png';

function ContentIdeas({ contentPlan, setSelectedIdea, selectedIdea, setIsLoading, setContentPlan, setIsContentIdeasLoading, isContentIdeasLoading }: {
    setSelectedIdea: React.Dispatch<React.SetStateAction<ContentIdeaData | null>>,
    contentPlan: ContentPlanData,
    selectedIdea: ContentIdeaData | null,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setContentPlan: React.Dispatch<React.SetStateAction<ContentPlanData | null>>,
    isContentIdeasLoading: boolean,
    setIsContentIdeasLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) {
    const shimmer = (
        <>
            <ShimmerCategoryItem />
            <ShimmerCategoryItem
                hasImage
                imageType="circular"
                imageWidth={100}
                imageHeight={100}
                title
            />

            <ShimmerCategoryItem
                hasImage
                imageType="circular"
                imageWidth={100}
                imageHeight={100}
                title
            />

            <ShimmerCategoryItem
                hasImage
                imageType="circular"
                imageWidth={100}
                imageHeight={100}
                text
            />
        </>
    );

    const povText = () => {
        if (contentPlan.pov === 'informative') {
            return "Being Informative";
        } else if (contentPlan.pov === 'entertainment') {
            return "Entertainment";
        }
        return "Audience Value";
    }

    return (
        <Container>
            {isContentIdeasLoading ? shimmer : <>
                <Row>
                    <Col sm={7}>
                        <div>
                            <span style={{ fontWeight: 500 }}>
                                Here’s Content Ideas that Provides: <mark><b>{povText()}</b></mark>
                            </span>
                        </div>
                        <div style={{ paddingTop: '8px' }}>
                            <p style={{ color: '#737373' }}>You can click on a content idea below and AI will do research
                                for you to find example videos on the internet.</p>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button size="lg" variant="outline-primary">
                                <img
                                    src={icon}
                                    width={20}
                                    height={20}
                                    alt="refresh-icon"
                                    onClick={() => {
                                        setSelectedIdea(null);
                                        setIsContentIdeasLoading(true);
                                        axios.post('/generate/refresh', { query: contentPlan.prompt, plan_id: contentPlan.id, pov: contentPlan.pov })
                                            .then((response) => {
                                                setIsContentIdeasLoading(false);
                                                setContentPlan({
                                                    id: contentPlan.id,
                                                    prompt: contentPlan.prompt,
                                                    content_ideas: response.data.content_ideas,
                                                    pov: contentPlan.pov
                                                });
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }}
                                />
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card style={{ background: '#F5F5F5' }}>
                            <Card.Body>
                                <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                                    <span>Sure! Here are 10 content ideas for a short form video format:</span>
                                    <ListGroup style={{ paddingTop: '16px' }}>
                                        {contentPlan.content_ideas.map((idea, idx) => {
                                            return (
                                                <ListGroup.Item key={idx} action active={idea.id === selectedIdea?.id} onClick={() => {
                                                    setSelectedIdea(idea);
                                                    setIsLoading(true);
                                                    let cp = { ...contentPlan };
                                                    const cpIdx = cp.content_ideas.findIndex(x => x.id === idea.id);
                                                    if (cp.content_ideas[cpIdx].invoked !== true) {
                                                        axios.post('/generate/invoke-research', { plan_id: cp.id, idea_id: idea.id })
                                                              .then(() => {
                                                                setIsLoading(true);
                                                              })
                                                              .catch((error) => {
                                                                console.log(error);
                                                              });
                                                    }
                                                    cp.content_ideas[cpIdx].invoked = true;
                                                    setContentPlan(cp);
                                                }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                                        {idea.invoked === true && (
                                                                <img
                                                                    style={{ marginRight: '8px', background: '#90ee90', borderRadius: '100%' }}
                                                                    src={graycheckicon}
                                                                    width={24}
                                                                    height={24}
                                                                    alt="checked-icon"
                                                                />
                                                            )}
                                                        {idea.invoked !== true && (
                                                                <img
                                                                    style={{ marginRight: '8px' }}
                                                                    src={grayicon}
                                                                    width={24}
                                                                    height={24}
                                                                    alt="unfilled-icon"
                                                                />
                                                            )}
                                                        <span style={{ fontWeight: 500 }}>{idea.idea_content}</span>
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row></>}
        </Container>
    );
}

export default ContentIdeas;
