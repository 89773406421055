import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from './assets/logo.png';
import profile from './assets/profile.png';
import './NavbarContainer.css';
import React from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import Cookies from 'js-cookie';

function NavbarContainer() {
  const jwtToken = Cookies.get('jwtToken');
  const isAuthenticated = jwtToken != null;
  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <div className='logo2' />
        <img
          src={logo}
          width={250}
          height={104}
          className="logo"
          alt="CreatorPush logo"
        />
      </Navbar.Brand>
      {isAuthenticated &&
        <Navbar.Collapse className="justify-content-end" style={{ marginRight: '60px' }}>
          <Dropdown>
            <Dropdown.Toggle as={React.forwardRef<HTMLAnchorElement>((props, ref) => {
              const onClick = props['onClick'];
              return <div style={{ height: '60px', width: '160px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}><a href=""
                ref={ref}
                onClick={(e) => {
                  e.preventDefault();
                  return onClick(e);
                }}>
                <img
                  src={profile}
                  width={32}
                  height={32}
                  alt="CreatorPush logo"
                />
              </a>
              </div>;
            })} id="dropdown-custom-components">
              Icon Toggle
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={() => {
                Cookies.remove('jwtToken');
                window.location.href = '/login';
              }}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>}
    </Navbar>
  );
}

export default NavbarContainer;
