import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Card, Form, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

function Login() {

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const[validationMsg, setValidationMsg] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const {email, password} = formData;
        const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);
        if (!isValidEmail) {
            setValidationMsg('Invalid email format.');
            return;
        }

        axios.post('/login', formData)
            .then((response) => {
                const data = response.data;
                const expires = new Date();
                expires.setDate(expires.getDate() + 7);
                document.cookie = "jwtToken=" + data.token + ";expires=" + expires.toUTCString() + ";path=/";
                if (data.hasOwnProperty('onboarding_eligible') && data['onboarding_eligible'] === true) {
                    window.location.href = '/onboarding'
                } else {
                    window.location.href = '/';
                }
            })
            .catch((error) => {
                setValidationMsg(error.response.data.message);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const navigate = useNavigate();
    const { state } = useLocation();
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ width: '18rem', marginTop: '100px' }}>
                {validationMsg != '' && <Alert key={'danger'} variant={'danger'}>
                    {validationMsg}
                </Alert>}
                {state?.isRegister === true && <Alert key={'success'} variant={'success'}>
                    Thank you for registering!
                </Alert>}
                <Card>
                    <Card.Body>
                        <Card.Title>Log In</Card.Title>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" name='email' onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" name='password' onChange={handleChange} />
                            </Form.Group>
                            <div style={{ marginTop: '30px' }}>
                                <Button variant="primary" type="submit">
                                    Log in
                                </Button>{' '}
                                <Button variant="outline-primary" onClick={() => {
                                    navigate('/register');
                                }}>
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Stack>
        </div>
    );
}

export default Login;
