import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import Dashboard from './home_dashboard/Dashboard';
import GenerateBar from './home_dashboard/GenerateBar';
import { useParams } from 'react-router-dom';
import ContentPlan from './home_dashboard/ContentPlan';
import { ShimmerPostDetails } from "react-shimmer-effects";

export type ContentPlanData = {
  id: string,
  prompt: string
  content_ideas: ContentIdeaData[],
  pov: string
}

export type ContentIdeaData = {
  id: string,
  idea_content: string,
  invoked: boolean
}

function ContentPlanHome() {
  const params = useParams();
  const planID = params.planID;
  const [contentPlan, setContentPlan] = useState<ContentPlanData | null>(null);
  const [isContentIdeasLoading, setIsContentIdeasLoading] = useState(false);
  
  useEffect(() => {
    if (isContentIdeasLoading === true) {
      return;
    }
    setContentPlan(null);
    axios.get(`/plan/${planID}`)
      .then((response) => {
        const data = response.data;
        setContentPlan({
          id: data.id,
          prompt: data.prompt,
          content_ideas: data.content_ideas,
          pov: data.pov
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.planID, isContentIdeasLoading]);

  if (contentPlan == null) {
    return (<div style={{ width: '100%' }}>
      <Stack>
        <div style={{paddingLeft: 64, paddingRight: 64}}>
          <ShimmerPostDetails card cta variant="SIMPLE" />
        </div>
      </Stack>
    </div>);
  }

  return (
    <div style={{ width: '100%' }}>
      <Stack>
        <GenerateBar contentPlan={contentPlan} setIsLoading={setIsContentIdeasLoading} />
        <ContentPlan 
          contentPlan={contentPlan} 
          setContentPlan={setContentPlan} 
          isContentIdeasLoading={isContentIdeasLoading}
          setIsContentIdeasLoading={setIsContentIdeasLoading} />
      </Stack>
    </div>
  );
}

export default ContentPlanHome;
