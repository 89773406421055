import Stack from 'react-bootstrap/Stack';
import { Button, ListGroup } from 'react-bootstrap';
import logo from './assets/icon.png';
import WorkWithAIGroupSection from './WorkWithAIGroupSection';
import TipsAndTricksSection from './TipsAndTricksSection';
import CommunitySection from './CommunitySection';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

type ContentPlan = {
    id: string,
    title: string
};

export const ContentPlansContext = React.createContext<{
        contentPlans: ContentPlan[], 
        setContentPlans: React.Dispatch<React.SetStateAction<ContentPlan[]>
    >}>({ contentPlans: [], setContentPlans: () => {} });

function LeftRail({ children }) {
    let navigate = useNavigate();
    const [contentPlans, setContentPlans] = useState<ContentPlan[]>([]);
    return (
        <ContentPlansContext.Provider value={{ contentPlans, setContentPlans }}>
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '100vh' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '450px', background: '#F5F5F5' }}>
                    <Stack style={{ gap: '38px', display: 'flex', flexDirection: 'column', padding: '28px' }}>
                        <div>
                            <Button size="lg" variant="primary" onClick={() => {
                                navigate('/');
                            }}>
                                <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <img
                                        style={{ marginTop: '-2px', marginRight: '5px' }}
                                        src={logo}
                                        width={18}
                                        height={18}
                                        alt="icon"
                                    />
                                    Create Plan
                                </div>
                            </Button>
                        </div>
                        <div>
                            <WorkWithAIGroupSection />
                        </div>
                        <div><TipsAndTricksSection /></div>
                        <div><CommunitySection /></div>
                    </Stack>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    {children}
                </div>
            </div>
        </ContentPlansContext.Provider>
    );
}

export default LeftRail;
