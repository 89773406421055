import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import Dashboard from './home_dashboard/Dashboard';
import GenerateBar from './home_dashboard/GenerateBar';
import ContentPlan from './home_dashboard/ContentPlan';
import { ShimmerCategoryItem } from "react-shimmer-effects";

export type UnitData = {
  video_units: any,
  static_units: any
};

function Home() {
  const [tags, setTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UnitData[] | null>(null);

  const shimmer = (
    <>
        <ShimmerCategoryItem />
        <ShimmerCategoryItem
            hasImage
            imageType="circular"
            imageWidth={100}
            imageHeight={100}
            title
        />

        <ShimmerCategoryItem
            hasImage
            imageType="circular"
            imageWidth={100}
            imageHeight={100}
            title
        />

        <ShimmerCategoryItem
            hasImage
            imageType="circular"
            imageWidth={100}
            imageHeight={100}
            text
        />
    </>
);

  return (
    <div style={{width: '100%'}}>
      <Stack>
        <GenerateBar contentPlan={null} setIsLoading={setIsLoading} />
        {isLoading === true &&
        (<div style={{ width: '100%', justifyContent: 'center', display: 'flex', marginTop: '56px' }}>
            <Col sm={9}>
                <Stack>
                    <div>
                      {shimmer}
                    </div>
                </Stack>
            </Col>
        </div>)}
      </Stack>
    </div>
  );
}

export default Home;
