import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, OverlayTrigger, Popover, Row, Stack } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis'
import VidPopover from './VidPopover';
import { ShimmerPostItem } from "react-shimmer-effects";

type VidUnit = {
    id: string,
    video_uri: string,
    embed: string,
    // download the actual image so won't expire.
    thumbnail_url: string,
    title: string,
    likes: string,
    author: string,
}

function VidUnit(data: VidUnit) {

    const [showPopover, setShowPopover] = useState(false);
    const [selected, setSelected] = useState(false);
    const [shimmer, setShimmer] = useState(false); 
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;

    if (shimmer === true) {
        return <ShimmerPostItem imageHeight={205} card title text cta />;
    }

    return (
        <>
            <div onClick={() => setShowPopover(true)}>
                <div onMouseLeave={() => setSelected(false)} style={{ position: 'absolute', background: 'grey', opacity: '0.1', height: '398px', width: '255px', zIndex: '10', borderRadius: '4px' }} hidden={!selected}></div>
                <div onMouseEnter={() => setSelected(true)}>
                    <Card style={{width: '255px'}}>
                        <Card.Body>
                            <Card.Img style={{ objectFit: 'cover', height: '300px', width: '200px' }} height='300px' width='200px' src={`https://storage.googleapis.com/creator_push_images/images/${data.author}_${data.id}.jpg`}
                                onError={({currentTarget}) => {
                                    if (currentTarget.src === data.thumbnail_url) {
                                        setShimmer(true);
                                    }
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=data.thumbnail_url;
                                }}></Card.Img>
                            <Card.Text style={{ marginTop: '8px' }}>
                                <LinesEllipsis
                                    text={data.title}
                                    maxLine='2'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                />
                            </Card.Text>
                            <Card.Footer>Likes: {data.likes}</Card.Footer>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Modal show={showPopover} onHide={() => setShowPopover(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VidPopover embed={data.embed} />
                </Modal.Body>
            </Modal>
        </>);
}

export default VidUnit;
